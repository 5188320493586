import axios, { AxiosError, AxiosResponse } from 'axios';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import debounce from 'lodash.debounce';
import moment from 'moment';
import printJS from 'print-js';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Trash2, Upload } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ellipse from '../assets/images/ellipse.svg';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import ImportCsvModal from '../components/import-csv-modal';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { DateRange } from '../enums/date-range';
import { Entity } from '../enums/entity';
import { Role } from '../enums/role';
import { SaleStatus } from '../enums/sale-status';
import { useStores } from '../hooks/use-stores.hook';
import { DateRangeData } from '../interfaces/date-range-data';
import { GetSalesQuery } from '../interfaces/get-sales-query';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { ManualSaleProduct } from '../interfaces/manual-sale-product';
import { Organization } from '../interfaces/organization';
import { Sale } from '../interfaces/sale';
import { SaleProduct } from '../interfaces/sale-product';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { arrayBufferToJson, formatNumber, getDatesGivenDateRange } from '../services/helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface FiltersSessionStorage {
  internalId: string;
  storeIds: string | null;
  status: SaleStatus | null;
  customer: string;
  sellerId: number | null;
  dateRange: DateRange;
  startDateStr: string | null;
  endDateStr: string | null;
}

interface Filters {
  internalId: string;
  storeIds: number[] | null;
  status: SaleStatus | null;
  customer: string;
  sellerId: number | null;
  hasManualProducts: boolean;
  hasNotes: boolean;
  online: boolean;
  physical: boolean;
  totals: boolean;
}

const debounceGetSales = debounce(async (getSalesQuery: GetSalesQuery, cb: (sales: Sale[]) => void) => {
  const ss: Sale[] = await api.getSales(getSalesQuery);
  cb(ss);
}, 500);

const dateRange: DateRange = DateRange.ThisYear;

const customStyles = {
  container: (provided: any, state: any) => {
    return {
      ...provided,
      flex: 1,
      fontFamily: 'Inter-Regular',
      fontSize: '14px',
      borderColor: '#a0aec1',
    };
  },
  option: (provided: any, state: any) => ({
    ...provided,
  }),
  control: (provided: any, state: any) => {
    return {
      ...provided,
    };
  },
  singleValue: (provided: any, state: any) => {
    return { ...provided };
  },
};

const SalesView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { store } = useSelector(storeSelector);
  const [sales, setSales] = useState<Sale[]>([]);
  const [filteredSales, setFilteredSales] = useState<Sale[]>([]);
  const [sellers, setSellers] = useState<User[]>([]);
  const [stores, setStores] = useState<Store[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [selectedSale, setSelectedSale] = useState<Sale | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [requestingPdf, setRequestingPdf] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange,
    dates: getDatesGivenDateRange(dateRange),
  });
  const [showColumnOnlineStore, setShowColumnOnlineStore] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters | null>();
  const [showImportCsvModal, setShowImportCsvModal] = useState<boolean>(false);
  const userStores: Store[] = useStores();

  const { units, totalPrice, totalCost, profit } = useMemo(() => {
    let units = 0;
    let totalPrice = 0;
    let totalCost = 0;
    let profit = 0;
    filteredSales.forEach((sale: Sale) => {
      sale.saleProducts.forEach((saleProduct: SaleProduct) => {
        units += saleProduct.quantity;
        totalCost += saleProduct.costPrice * saleProduct.quantity;
        totalPrice += saleProduct.total;
        profit += saleProduct.total - saleProduct.costPrice * saleProduct.quantity;
      });
      sale.manualSaleProducts.forEach((manualSaleProduct: ManualSaleProduct) => {
        if (manualSaleProduct.replaced) {
          return;
        }
        units += manualSaleProduct.quantity;
        totalPrice += manualSaleProduct.total;
        profit += manualSaleProduct.total;
      });
    });
    return { units, totalPrice, totalCost, profit };
  }, [filteredSales]);

  const getSales = useCallback(async () => {
    if (!filters) {
      return;
    }
    setRequesting(true);
    const getSalesQuery: GetSalesQuery = {
      startDate: dateRangeData.dates[0],
      endDate: dateRangeData.dates[1],
      organizationId: organization!.id,
      storeIds: filters.storeIds || [],
    };
    debounceGetSales(getSalesQuery, (ss: Sale[]) => {
      const mapSellers: Map<number, User> = new Map<number, User>();
      const mapStores: Map<number, Store> = new Map<number, Store>();
      ss.forEach((sale: Sale) => {
        if (sale?.user && !mapSellers.has(sale.user.id)) {
          mapSellers.set(sale.user.id, sale.user);
        }
        if (!mapStores.has(sale.store.id)) {
          mapStores.set(sale.store.id, sale.store);
        }
      });
      let filteredSales: Sale[] = [...ss];
      if (filters.internalId.length > 0) {
        const fuse = new Fuse(filteredSales, {
          // keys: ['internalId', 'internalReservationId'],
          // TODO: eliminar
          keys: ['internalId', 'internalReservationId', 'tmpInternalId'],
          threshold: 0,
          ignoreLocation: true,
        });
        const fuseResult: Fuse.FuseResult<Sale>[] = fuse.search(filters.internalId);
        filteredSales = fuseResult.map((result: Fuse.FuseResult<Sale>) => result.item);
      }
      if (filters.status) {
        filteredSales = filteredSales.filter((sale: Sale) => sale.status === filters.status);
      }
      if (filters.customer && filters.customer.length > 0) {
        const fuse = new Fuse(filteredSales, {
          keys: ['customer.name', 'customer.surnames', 'customer.phone', 'customer.secondaryPhone'],
          threshold: 0,
          ignoreLocation: true,
        });
        const fuseResult: Fuse.FuseResult<Sale>[] = fuse.search(filters.customer);
        filteredSales = fuseResult.map((result: Fuse.FuseResult<Sale>) => result.item);
      }
      if (filters.sellerId) {
        filteredSales = filteredSales.filter((sale: Sale) => sale.user.id === filters.sellerId);
      }
      if (filters.physical) {
        filteredSales = filteredSales.filter((sale: Sale) => sale.onlineSaleId === null);
      }
      if (filters.online) {
        filteredSales = filteredSales.filter((sale: Sale) => sale.onlineSaleId);
      }
      if (filters.hasManualProducts) {
        filteredSales = filteredSales.filter((sale: Sale) => sale.hasManualProducts);
      }
      if (filters.hasNotes) {
        filteredSales = filteredSales.filter((sale: Sale) => sale.notes && sale.notes.length > 0);
      }
      setFilteredSales(filteredSales);
      setSellers(Array.from(mapSellers.values()));
      setSales(ss);
      setRequesting(false);
      setShowColumnOnlineStore(organization.enabledOnlineStores);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, filters]);

  const columns: any[] = useMemo(() => {
    const data: any[] = [
      {
        Header: 'Número',
        id: 'internalId',
        disableFilters: true,
        // accessor: (sale: Sale) => sale.internalId || sale.internalReservationId,
        // TODO: eliminar
        accessor: (sale: Sale) => {
          let parts: string[] = [];
          if (sale.internalId) {
            parts.push(sale.internalId);
          }
          if (sale.internalReservationId) {
            parts.push(sale.internalReservationId);
          }
          if (parts.length === 0) {
            parts.push(sale.tmpInternalId);
          }
          return parts.join(' ');
        },
        Cell: ({ row }: any) => {
          const sale: Sale = row.original as Sale;
          return (
            <Link to={`/venta/${sale.id}`} className="link-sale">
              <div className="d-flex flex-row">
                <div className="d-flex flex-column">
                  <span className="fw-bold">{sale.internalId || sale.internalReservationId}</span>
                  {sale.internalId && sale.internalReservationId && sale.internalReservationId !== sale.internalId && <small className="fw-bold">{sale.internalReservationId}</small>}
                  {/* TODO: eliminar */}
                  {sale.tmpInternalId && (
                    <small className="fw-bold" style={{ color: 'orange' }} title="Identificador temporal. No estará disponible a partir del próximo 01/08/2023">
                      {sale.tmpInternalId}
                    </small>
                  )}
                </div>
                <div className="d-flex flex-column ms-1">
                  {sale.notes && sale.notes.length > 0 && <img src={ellipse} width={6} height={6} alt="ellipse" />}
                  {sale.hasManualProducts && (
                    <svg style={{ marginTop: sale.notes ? 1 : 0 }} xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7">
                      <defs>
                        <style></style>
                      </defs>
                      <circle style={{ fill: '#e09200' }} cx="3.5" cy="3.5" r="3.5" />
                    </svg>
                  )}
                </div>
              </div>
            </Link>
          );
        },
      },
      {
        Header: 'Tienda',
        id: 'store',
        disableFilters: true,
        accessor: (sale: Sale) => sale.store.name,
      },
      {
        Header: 'Fecha',
        id: 'createdAt',
        disableFilters: true,
        accessor: (sale: Sale) => moment(sale.createdAt).format('DD/MM/YYYY HH:mm'),
      },
    ];
    if (showColumnOnlineStore) {
      data.push({
        Header: 'Venta Online',
        id: 'onlineSaleId',
        disableFilters: true,
        accessor: (sale: Sale) => sale.onlineSaleId,
        Cell: ({ row }: any) => {
          const sale: Sale = row.original as Sale;
          if (sale.onlineSaleId) {
            return (
              <div className="d-flex flex-column">
                <span className="">{sale.onlineStore.name}</span>
                <span className="fw-bold">{sale.onlineSaleId}</span>
              </div>
            );
          } else {
            return null;
          }
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.createdAt);
          const momentB = moment(b.original.createdAt);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      });
    }
    data.push(
      ...[
        {
          Header: 'Estado',
          id: 'status',
          disableFilters: true,
          accessor: (sale: Sale) => {
            switch (sale.status) {
              case SaleStatus.Return:
                return 'Devolución';
              case SaleStatus.Finalized:
                return 'Finalizada';
              case SaleStatus.Reservation:
                return 'Reserva';
              case SaleStatus.Pending:
                return 'Pendiente';
              case SaleStatus.Deleted:
                return 'Eliminada';
              case SaleStatus.Cancelled:
                return 'Cancelada';
              default:
                return 'Sin estado';
            }
          },
          Cell: ({ row, value }: any) => {
            const sale: Sale = row.original as Sale;
            let classStr = '';
            switch (sale.status) {
              case SaleStatus.Return:
                classStr = 'badge my-danger';
                break;
              case SaleStatus.Finalized:
                classStr = 'badge my-success';
                break;
              case SaleStatus.Reservation:
                classStr = 'badge my-warning';
                break;
              case SaleStatus.Pending:
                classStr = 'badge my-info';
                break;
              case SaleStatus.Deleted:
                classStr = 'badge my-danger';
                break;
              case SaleStatus.Cancelled:
                classStr = 'badge my-gray';
                break;
              default:
                classStr = '';
                break;
            }
            return <span className={classStr}>{value}</span>;
          },
        },
        {
          Header: 'Cliente',
          id: 'customer',
          disableFilters: true,
          accessor: (sale: Sale) => {
            let name = 'Sin nombre';
            if (sale.customer.name) {
              name = sale.customer.name;
            }
            if (sale.customer.surnames) {
              name += ` ${sale.customer.surnames}`;
            }
            return name.trim();
          },
        },
        {
          Header: 'Unidades',
          id: 'units',
          disableFilters: true,
          accessor: (sale: Sale) => sale.units,
        },
        {
          Header: 'Vendedor',
          id: 'seller',
          disableFilters: true,
          accessor: (sale: Sale) => {
            let seller = '';
            if (sale.user) {
              if (sale.user.name) {
                seller = sale.user.name;
              }
              if (sale.user.surnames) {
                seller += ` ${sale.user.surnames}`;
              }
            }
            return seller.trim();
          },
        },
        {
          Header: 'Total',
          id: 'total',
          disableFilters: true,
          accessor: (sale: Sale) => sale.total,
          Cell: ({ row }: any) => {
            const sale: Sale = row.original as Sale;
            return `${formatNumber(sale.total)}€`;
          },
        },
      ],
    );
    if (user.role === Role.SuperAdmin) {
      data.push({
        Header: 'Acciones',
        id: 'actions',
        disableFilters: true,
        Cell: ({ row }: any) => {
          const sale: Sale = row.original as Sale;
          if (!sale.onlineSaleId) {
            return (
              <Trash2
                type="button"
                className="mx-2"
                onClick={() => {
                  setSelectedSale(sale);
                  setShowConfirmModal(true);
                }}
                size={14}
              />
            );
          } else {
            return null;
          }
        },
      });
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showColumnOnlineStore]);

  const selectedStores: { label: string; value: number }[] = useMemo(() => {
    if (!filters?.storeIds) {
      return [];
    }
    return stores.filter((store: Store) => filters.storeIds!.includes(store.id)).map((store: Store) => ({ label: store.name, value: store.id }));
  }, [stores, filters?.storeIds]);

  useEffect(() => {
    const newFilters: Filters = {
      internalId: '',
      storeIds: [],
      status: null,
      customer: '',
      sellerId: null,
      hasNotes: false,
      hasManualProducts: false,
      online: false,
      physical: false,
      totals: false,
    };
    const value: string | null = sessionStorage.getItem('search-sales');
    if (value) {
      try {
        const filtersSessionStorage: FiltersSessionStorage = JSON.parse(value);
        newFilters.internalId = filtersSessionStorage.internalId;
        newFilters.storeIds = filtersSessionStorage?.storeIds
          ? filtersSessionStorage.storeIds
              .split(',')
              .filter((e: string) => !isNaN(e as any))
              .map((e: string) => parseInt(e, 10))
          : [];
        newFilters.status = filtersSessionStorage.status;
        newFilters.customer = filtersSessionStorage.customer;
        newFilters.sellerId = filtersSessionStorage.sellerId;
        setDateRangeData({
          dateRange: filtersSessionStorage.dateRange,
          dates: [
            moment(filtersSessionStorage.startDateStr).isValid() ? moment(filtersSessionStorage.startDateStr).toDate() : null,
            moment(filtersSessionStorage.endDateStr).isValid() ? moment(filtersSessionStorage.endDateStr).toDate() : null,
          ],
        });
      } catch (e) {
        sessionStorage.removeItem('search-sales');
      }
    }
    setFilters(newFilters);
    const getStores = async () => {
      try {
        const ss: Store[] = await api.getStores(organization!.id);
        setStores(ss);
      } catch (e) {}
    };
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!filters) {
      return;
    }
    const filtersSessionStorage: FiltersSessionStorage = {
      internalId: filters.internalId,
      storeIds: filters.storeIds && filters.storeIds.length > 0 ? filters.storeIds.join(',') : null,
      status: filters.status,
      customer: filters.customer,
      sellerId: filters.sellerId,
      dateRange: dateRangeData.dateRange,
      startDateStr: moment(dateRangeData.dates[0]).format('YYYY-MM-DD HH:mm'),
      endDateStr: moment(dateRangeData.dates[1]).format('YYYY-MM-DD HH:mm'),
    };
    sessionStorage.setItem('search-sales', JSON.stringify(filtersSessionStorage));
    getSales();
  }, [dateRangeData.dateRange, dateRangeData.dates, filters, getSales]);

  const deleteSale = async (s: Sale) => {
    try {
      await api.deleteSale(s.id);
      getSales();
      let text: string = '';
      if (s.status === SaleStatus.Finalized) {
        text = 'Venta eliminada correctamente';
      } else if (s.status === SaleStatus.Return) {
        text = 'Devolución eliminada correctamente';
      } else if (s.status === SaleStatus.Reservation) {
        text = 'Reserva eliminada correctamente';
      } else if (s.status === SaleStatus.Cancelled) {
        text = 'Venta cancelada eliminada correctamente';
      }
      myToastr.success(text);
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    if (!filters) {
      return;
    }
    const data: any[] = [];
    sales.forEach((sale: Sale) => {
      let status = '';
      switch (sale.status) {
        case SaleStatus.Return:
          status = 'Devolución';
          break;
        case SaleStatus.Finalized:
          status = 'Finalizada';
          break;
        case SaleStatus.Reservation:
          status = 'Reserva';
          break;
        case SaleStatus.Pending:
          status = 'Pendiente';
          break;
        case SaleStatus.Deleted:
          status = 'Eliminada';
          break;
        case SaleStatus.Cancelled:
          status = 'Cancelada';
          break;
      }
      let customerName = 'Sin nombre';
      if (sale.customer.name && sale.customer.name !== '') {
        customerName = sale.customer.name;
      }
      if (sale.customer.surnames && sale.customer.surnames !== '') {
        customerName = `${customerName} ${sale.customer.surnames}`;
      }
      let sellerName: string = '';
      if (sale.user) {
        if (sale.user.name && sale.user.name !== '') {
          sellerName = sale.user.name;
        }
        if (sale.user.surnames && sale.user.surnames !== '') {
          sellerName = `${sellerName} ${sale.user.surnames}`;
        }
      }
      sale.saleProducts.forEach((saleProduct: SaleProduct) => {
        data.push({
          Número: sale?.internalId ? sale.internalId : sale.internalReservationId,
          Tienda: sale.store.name,
          Fecha: moment(sale.createdAt).format('DD/MM/YYYY HH:mm'),
          Estado: status,
          Cliente: customerName,
          Vendedor: sellerName,
          Sku: saleProduct.product.sku,
          Unidades: saleProduct.quantity,
          Total: `${formatNumber(saleProduct.pvp)}€`,
        });
      });
      sale.manualSaleProducts.forEach((manualSaleProduct: ManualSaleProduct) => {
        if (manualSaleProduct.replaced) {
          return;
        }
        data.push({
          Número: sale?.internalId ? sale.internalId : sale.internalReservationId,
          Tienda: sale.store.name,
          Fecha: moment(sale.createdAt).format('DD/MM/YYYY HH:mm'),
          Estado: status,
          Cliente: customerName,
          Vendedor: sellerName,
          Sku: manualSaleProduct.name,
          Unidades: manualSaleProduct.quantity,
          Total: `${formatNumber(manualSaleProduct.pvp)}€`,
        });
      });
    });
    setCsvData(data);
  };

  const getSalesPdf = async () => {
    if (requestingPdf) {
      return;
    }
    setRequestingPdf(true);
    try {
      const getSalesQuery: GetSalesQuery = {
        startDate: dateRangeData.dates[0],
        endDate: dateRangeData.dates[1],
        organizationId: organization!.id,
        storeIds: filters?.storeIds || [],
      };
      const result: AxiosResponse = await api.getSalesPdf(getSalesQuery);
      myToastr.remove();
      if (result.headers && result.headers['content-type'] && result.headers['content-type'] === 'application/pdf') {
        const url: string = window.URL.createObjectURL(new Blob([result.data], { type: 'application/pdf' }));
        printJS(url, 'pdf');
      } else {
        const data: { message: string } = arrayBufferToJson(result.data);
        myToastr.info(data.message);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        const axiosError: AxiosError = e as AxiosError;
        if (axiosError.response?.data) {
          const httpExceptionDto: HttpExceptionDto = arrayBufferToJson(axiosError.response.data);
          myToastr.remove();
          myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
        }
      }
    } finally {
      setRequestingPdf(false);
    }
  };

  const onCloseImportCsvModal = (result: boolean) => {
    if (result) {
      getSales();
    }
    setShowImportCsvModal(false);
  };

  if (!filters) {
    return null;
  }

  if (userStores.length === 0) {
    return <NoStoresAssigned />;
  }

  return (
    <div className="sales p-4">
      <div className="d-flex align-items-center flex-wrap flex-sm-nowrap __header">
        <div className="d-flex flex-grow-1">
          <h1 className="me-4">Ventas</h1>
          <div className={clsx('check-switch', { switched: filters.physical })}>
            <input type="checkbox" checked={filters.physical} onChange={(e: any) => setFilters({ ...filters, physical: e.target.checked, online: e.target.checked ? false : filters.online })} />
            <label className="filter-notes">Ventas tienda</label>
          </div>
          {showColumnOnlineStore && (
            <div className={clsx('check-switch', { switched: filters.online })}>
              <input type="checkbox" checked={filters.online} onChange={(e: any) => setFilters({ ...filters, online: e.target.checked, physical: e.target.checked ? false : filters.physical })} />
              <label className="filter-notes">Ventas Online</label>
            </div>
          )}
          <div className={clsx('check-switch', { switched: filters.hasManualProducts })}>
            <input type="checkbox" checked={filters.hasManualProducts} onChange={(e: any) => setFilters({ ...filters, hasManualProducts: e.target.checked })} />
            <label className="filter-notes">Con producto manual</label>
          </div>
          <div className={clsx('check-switch', { switched: filters.hasNotes })}>
            <input type="checkbox" checked={filters.hasNotes} onChange={(e: any) => setFilters({ ...filters, hasNotes: e.target.checked })} />
            <label className="filter-notes">Con notas</label>
          </div>
          {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
            <div className={clsx('check-switch', { switched: filters.totals })}>
              <input type="checkbox" checked={filters.totals} onChange={(e: any) => setFilters({ ...filters, totals: e.target.checked })} />
              <label className="filter-notes">Totales</label>
            </div>
          )}
        </div>
        {(user.role === Role.SuperAdmin || user.role === Role.Admin || user.role === Role.Manager) && (
          <div
            className="d-flex align-items-center export-csv cursor-pointer"
            onClick={() => {
              if (!store) {
                myToastr.error(`Selecciona una tienda`);
                return;
              }
              setShowImportCsvModal(true);
            }}
            title="Importar ventas"
          >
            <Upload className="me-1" size={14} /> Importar
          </div>
        )}
        {sales.length > 0 && (
          <React.Fragment>
            <CSVLink filename="ventas.csv" className="d-flex align-items-center export-csv" data={csvData} onClick={generateCsv} title="Exportar ventas">
              <Download className="me-1" size={14} /> Exportar CSV
            </CSVLink>
            <div className={clsx('d-flex flex-row align-items-center export-csv me-2', requestingPdf ? 'cursor-not-allowed' : 'cursor-pointer')} onClick={getSalesPdf}>
              <Download className="me-1" size={14} />
              <span>Exportar PDF</span>
            </div>
          </React.Fragment>
        )}
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={true}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
      </div>
      {filters.totals && (
        <div className="totals-wrapper d-flex justify-content-around __totals">
          <span className="totals-info">Precio coste: {formatNumber(totalCost)}€</span>
          <span className="totals-info">Precio total: {formatNumber(totalPrice)}€</span>
          <span className="totals-info">Beneficio: {formatNumber(profit)}€</span>
          <span className="totals-info">Unidades: {formatNumber(units, true)}</span>
        </div>
      )}
      <div className="filters-bar d-flex align-items-center my-3 __filters">
        <div className="me-1 sup-label" style={{ width: 200 }}>
          <span className="">Buscar</span>
          <input
            className="input-filter"
            type="text"
            placeholder="Buscar por id..."
            value={filters.internalId}
            onChange={(e) => setFilters({ ...filters, internalId: e.target.value || '' })}
            style={{ width: '100%' }}
          />
        </div>
        {stores.length > 0 && (
          <div className="mx-1 sup-label" style={{ minWidth: 200 }}>
            <span className="">Tiendas</span>
            <Select
              isClearable
              onChange={(e: any) => setFilters({ ...filters, storeIds: e.map((s: { label: string; value: number }) => s.value) })}
              closeMenuOnSelect={false}
              value={selectedStores}
              isMulti
              options={stores.map((s: Store) => ({ value: s.id, label: s.name }))}
              placeholder="Tiendas..."
              styles={customStyles}
              className="select-comp"
            />
          </div>
        )}
        <div className="mx-1 sup-label" style={{ width: 200 }}>
          <span className="">Estado</span>
          <select className="input-filter" value={filters.status || ''} onChange={(e) => setFilters({ ...filters, status: e.target.value as any })} style={{ width: '100%' }}>
            <option value="">Todos los estados...</option>
            <option value={SaleStatus.Finalized}>Finalizada</option>
            <option value={SaleStatus.Return}>Devolución</option>
            <option value={SaleStatus.Reservation}>Reserva</option>
            <option value={SaleStatus.Pending}>Pendiente</option>
            <option value={SaleStatus.Cancelled}>Cancelada</option>
          </select>
        </div>
        <div className="mx-1 sup-label" style={{ width: 200 }}>
          <span className="">Cliente</span>
          <input
            className="input-filter"
            type="text"
            placeholder="Buscar por cliente..."
            value={filters.customer}
            onChange={(e) => setFilters({ ...filters, customer: e.target.value || '' })}
            style={{ width: '100%' }}
          />
        </div>
        <div className="mx-1 sup-label" style={{ width: 200 }}>
          <span className="">Usuario</span>
          <select
            className="input-filter"
            value={filters.sellerId || ''}
            onChange={(e) => setFilters({ ...filters, sellerId: e.target?.value ? parseInt(e.target.value, 10) : null })}
            style={{ width: '100%' }}
          >
            <option value="">Todos los usuarios...</option>
            {sellers.map((user: User) => (
              <option key={user.id} value={user.id}>
                {user.name} {user.surnames}
              </option>
            ))}
          </select>
        </div>
        <div className="mx-1 sup-label" style={{ width: 200 }}>
          <button
            className="ms-2 clear-filters"
            disabled={requesting}
            onClick={() => {
              setFilters({
                physical: false,
                online: false,
                hasManualProducts: false,
                hasNotes: false,
                totals: false,
                internalId: '',
                storeIds: [],
                status: null,
                customer: '',
                sellerId: null,
              });
              setDateRangeData({
                dateRange,
                dates: getDatesGivenDateRange(dateRange),
              });
            }}
          >
            Limpiar filtros
          </button>
        </div>
      </div>
      {requesting ? (
        <div className="loader">
          <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
        </div>
      ) : (
        <Table
          data={filteredSales}
          columns={columns}
          noDataMessage="No hay ventas"
          initialState={{
            pageSize: Constants.LIMIT_RESULTS,
          }}
        />
      )}
      {selectedSale && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Venta"
          content={`¿Estás seguro que quieres eliminar la venta ${selectedSale.internalId || selectedSale.internalReservationId}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteSale(selectedSale);
            }
            setSelectedSale(null);
          }}
        ></ConfirmModal>
      )}
      <ImportCsvModal show={showImportCsvModal} closeModal={onCloseImportCsvModal} entity={Entity.Sale} />
    </div>
  );
};

export default SalesView;
