import axios, { AxiosError } from 'axios';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { X } from 'react-feather';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import Modal from 'react-modal';
import { HttpExceptionDto } from '../interfaces/http-exception.dto';
import { Product } from '../interfaces/product';
import { ProductImage } from '../interfaces/product-image';
import { RawImage } from '../interfaces/raw-image';
import { api } from '../services/api';
import { isValidUrl } from '../services/helpers';
import myToastr from '../services/toastr';

enum TypeImage {
  Url = 'url',
  File = 'file',
  Camera = 'camera',
}

interface Props {
  returnRawData: boolean;
  product: Product;
  show: boolean;
  closeModal: (productImage: ProductImage[] | RawImage | null) => void;
}

const UploadProductImageModal = (props: Props) => {
  const [typeImage, setTypeImage] = useState<TypeImage>(TypeImage.File);
  const [files, setFiles] = useState<File[]>([]);
  const [link, setLink] = useState<string | null>(null);
  const [dataUri, setDataUri] = useState<string | null>(null);
  const [requesting, setRequesting] = useState<boolean>(false);
  const imageInputFileRef = useRef<any>(null);
  const isValidLink: boolean = useMemo(() => {
    return link !== null && link.length > 0 && isValidUrl(link);
  }, [link]);

  useEffect(() => {
    setDataUri(null);
  }, [typeImage]);

  const close = () => {
    props.closeModal(null);
  };

  const uploadImage = async () => {
    if (files.length > 0) {
      setRequesting(true);
      try {
        const productImages: ProductImage[] = [];
        for (const file of files) {
          const productImage: ProductImage = await api.uploadProductImage(props.product.id, file);
          productImages.push(productImage);
        }
        setFiles([]);
        props.closeModal(productImages);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const axiosError: AxiosError = e as AxiosError;
          if (axiosError.response?.data) {
            const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
            myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
          }
        }
      }
      setRequesting(false);
      return;
    } else if (link && link.length > 0) {
      try {
        setRequesting(true);
        const productImage: ProductImage = await api.uploadProductImageFromLink(props.product.id, { link });
        setLink(null);
        props.closeModal([productImage]);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const axiosError: AxiosError = e as AxiosError;
          if (axiosError.response?.data) {
            const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
            myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
          }
        }
      }
      setRequesting(false);
    } else if (dataUri) {
      try {
        setRequesting(true);
        const productImage: ProductImage = await api.uploadProductImageFromBase64(props.product.id, { image: dataUri });
        setDataUri(null);
        props.closeModal([productImage]);
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const axiosError: AxiosError = e as AxiosError;
          if (axiosError.response?.data) {
            const httpExceptionDto: HttpExceptionDto = axiosError.response.data;
            myToastr.error(Array.isArray(httpExceptionDto.message) ? httpExceptionDto.message.join('\n') : httpExceptionDto.message);
          }
        }
      }
      setRequesting(false);
    }
  };

  const save = async () => {
    if (props.returnRawData) {
      props.closeModal({ files, link, dataUri } as RawImage);
      return;
    }
    await uploadImage();
  };

  const onTakePhoto = (dataUri: string | null) => {
    setDataUri(dataUri);
  };

  return (
    <Modal className="vercomi-modal my-form upload-product-image-modal" isOpen={props.show} onRequestClose={close} shouldCloseOnOverlayClick={false}>
      <form onSubmit={() => {}}>
        <div className="content">
          <div className="d-flex justify-content-between mb-3">
            <div className="title">Nueva imagen del producto {props.product.name}</div>
            <button type="button" className="close-button-modal" onClick={close} title="Cerrar">
              <X size={16} />
            </button>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex flex-row my-2">
              <label>Fuente:</label>
              <div className="d-flex flex-row align-items-center mx-2">
                <input
                  checked={typeImage === TypeImage.File}
                  type="radio"
                  value={TypeImage.File}
                  onChange={(e: any) => {
                    setTypeImage(e.target.value);
                    setLink(null);
                  }}
                />
                <label className="ms-2">Fichero</label>
              </div>
              <div className="d-flex flex-row align-items-center">
                <input
                  checked={typeImage === TypeImage.Url}
                  type="radio"
                  value={TypeImage.Url}
                  onChange={(e: any) => {
                    setTypeImage(e.target.value);
                    setFiles([]);
                  }}
                />
                <label className="ms-2">Enlace</label>
              </div>
              <div className="d-flex flex-row align-items-center mx-2">
                <input
                  checked={typeImage === TypeImage.Camera}
                  type="radio"
                  value={TypeImage.Camera}
                  onChange={(e: any) => {
                    setTypeImage(e.target.value);
                    setFiles([]);
                  }}
                />
                <label className="ms-2">Cámara</label>
              </div>
            </div>
            <div className="my-2">
              {typeImage === TypeImage.File && (
                <React.Fragment>
                  {files.length > 0 ? (
                    <div className="d-flex flex-row">
                      {files.map((file: File, index: number) => (
                        <div key={index} className="position-relative container-image d-flex align-items-center justify-content-center me-2 mb-2">
                          <img className="product-image" src={URL.createObjectURL(file)} alt="" />
                          <span
                            className="delete-image"
                            onClick={() => {
                              const newFiles = [...files];
                              newFiles.splice(index, 1);
                              setFiles(newFiles);
                            }}
                          >
                            <X color="white" size="15" />
                          </span>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <React.Fragment>
                      <button type="button" className="open-file" onClick={() => imageInputFileRef.current.click()}>
                        Seleccionar imágenes
                      </button>
                      <input
                        ref={imageInputFileRef}
                        multiple
                        type="file"
                        accept="image/*"
                        onClick={(event: any) => {
                          event.target.value = null;
                        }}
                        onChange={(e) => {
                          if (e.target.files !== null && e.target.files.length > 0) {
                            setFiles(Array.from(e.target.files));
                          }
                        }}
                        style={{ display: 'none' }}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
              {typeImage === TypeImage.Url && (
                <React.Fragment>
                  <input type="text" className={clsx({ error: link && link.length > 0 && !isValidLink })} placeholder="Enlace de la imagen" onChange={(e: any) => setLink(e.target.value)} />
                  {link && link.length > 0 && !isValidLink && <div className="error-message">Enlace inválido</div>}
                </React.Fragment>
              )}
              {typeImage === TypeImage.Camera && (
                <React.Fragment>
                  {dataUri ? (
                    <div className="container-product-image">
                      <img src={dataUri} alt="" />
                      <div className="d-flex align-items-center justify-content-center remove-icon">
                        <X onClick={() => onTakePhoto(null)} size={14} />
                      </div>
                    </div>
                  ) : (
                    <Camera
                      onTakePhotoAnimationDone={onTakePhoto}
                      onCameraError={(error) => {
                        myToastr.error(`Error al acceder a la cámara`);
                      }}
                      idealFacingMode={FACING_MODES.ENVIRONMENT}
                      idealResolution={{ width: 640, height: 480 }}
                      isFullscreen={false}
                      imageType={IMAGE_TYPES.JPG}
                      imageCompression={0.65}
                      isMaxResolution={true}
                    />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <button className={clsx(`save-button`)} type="button" disabled={(files.length === 0 && !isValidLink && !dataUri) || requesting} onClick={save}>
          Guardar
        </button>
      </form>
    </Modal>
  );
};

export default UploadProductImageModal;
