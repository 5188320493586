import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Download, Trash2 } from 'react-feather';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Row } from 'react-table';
import ellipse from '../assets/images/ellipse.svg';
import ConfirmModal from '../components/confirm-modal';
import DateRangeSelector from '../components/date-range-selector';
import NoStoresAssigned from '../components/no-stores-assigned';
import Table from '../components/table';
import { CashRegisterStatus } from '../enums/cash-register-status';
import { CashRegisterType } from '../enums/cash-register-type';
import { DateRange } from '../enums/date-range';
import { Role } from '../enums/role';
import { useStores } from '../hooks/use-stores.hook';
import { CashRegisterCheck } from '../interfaces/cash-register-check';
import { DateRangeData } from '../interfaces/date-range-data';
import { Organization } from '../interfaces/organization';
import { Store } from '../interfaces/store';
import { User } from '../interfaces/user';
import { api } from '../services/api';
import { Constants } from '../services/constants';
import { formatNumber, getDatesGivenDateRange } from '../services/helpers';
import { SelectColumnFilter } from '../services/table-helpers';
import myToastr from '../services/toastr';
import { useAppSelector } from '../store/hooks';
import { RootState } from '../store/store';
import { storeSelector } from '../store/store-slice';

interface CashRegistersTotals {
  totalDailyCash: number;
  totalDailyCards: number;
  totalRegisterCash: number;
  closingWithdrawals: number;
}

function CashRegisterStatusColumnFilter({ column: { filterValue, setFilter, preFilteredRows, id } }: any) {
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      <option value={CashRegisterStatus.Closed}>Cerrado</option>
      <option value={CashRegisterStatus.Resolved}>Resuelto</option>
      <option value={CashRegisterStatus.Incidence}>Incidencia</option>
    </select>
  );
}

const dateRange: DateRange = DateRange.ThisYear;

const CashRegistersView = () => {
  const user: User = useAppSelector((state: RootState) => state.auth.user!);
  const organization: Organization = useAppSelector((state: RootState) => state.auth.organization!);
  const { selectedStoreId, store, showCashRegiserModal } = useSelector(storeSelector);
  const tableRef: any = useRef<any>(null);
  const [cashRegisters, setCashRegisters] = useState<CashRegisterCheck[]>([]);
  const [filteredRows, setFilteredRows] = useState<Row[]>([]);
  const [csvData, setCsvData] = useState<any[]>([]);
  const [selectedCashRegister, setSelectedCashRegister] = useState<CashRegisterCheck | null>(null);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [requesting, setRequesting] = useState<boolean>(false);
  const [dateRangeData, setDateRangeData] = useState<DateRangeData>({
    dateRange,
    dates: getDatesGivenDateRange(dateRange),
  });
  const [showTotals, setShowTotals] = useState<boolean>(false);
  const cashRegistersTotals: CashRegistersTotals = useMemo(() => {
    const e: CashRegistersTotals = {
      totalDailyCash: 0,
      totalDailyCards: 0,
      totalRegisterCash: 0,
      closingWithdrawals: 0,
    };
    filteredRows.forEach((row: Row) => {
      const cashRegister: CashRegisterCheck = row.original as CashRegisterCheck;
      e.totalDailyCash += cashRegister.totalDailyCash;
      e.totalDailyCards += cashRegister.totalDailyCards;
      e.totalRegisterCash += cashRegister.totalRegisterCash;
      e.closingWithdrawals += cashRegister.closingWithdrawals;
    });
    return e;
  }, [filteredRows]);
  const stores: Store[] = useStores();

  useEffect(() => {
    const getCashRegisters = async () => {
      setRequesting(true);
      const cashRegisters: CashRegisterCheck[] = await api.getCashRegistersChecks(dateRangeData.dates[0], dateRangeData.dates[1], organization!.id, selectedStoreId);
      setCashRegisters(cashRegisters);
      setRequesting(false);
    };
    getCashRegisters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData, selectedStoreId, showCashRegiserModal]);

  const columns = useMemo(() => {
    const columnsData: any[] = [
      {
        Header: 'Número',
        accessor: 'internalId',
        Cell: ({ row }: any) => {
          const cashRegister: CashRegisterCheck = row.original;
          return (
            <Link to={`/arqueo/${cashRegister.id}`} className="link-sale">
              <span className="fw-bold">{cashRegister.internalId}</span>
              {((cashRegister.openingNotes && cashRegister.openingNotes.length > 0) || (cashRegister.closingNotes && cashRegister.closingNotes.length > 0)) && (
                <img src={ellipse} alt="ellipse" className="sale-with-note" />
              )}
            </Link>
          );
        },
      },
      {
        Header: 'Tienda',
        accessor: 'store.name',
        Filter: SelectColumnFilter,
      },
      {
        Header: 'Fecha apertura',
        id: 'createdAt',
        filter: 'fuzzyText',
        accessor: (cashRegisterCheck: CashRegisterCheck) => moment(cashRegisterCheck.createdAt).format('DD/MM/YYYY HH:mm'),
        Cell: ({ row }: any) => {
          const cashRegisterCheck: CashRegisterCheck = row.original;
          return moment(cashRegisterCheck.createdAt).format('DD/MM/YYYY HH:mm');
        },
        sortType: (a: any, b: any) => {
          const momentA = moment(a.original.date);
          const momentB = moment(b.original.date);
          return momentA.isBefore(momentB) ? -1 : 1;
        },
      },
      {
        Header: 'Fecha cierre',
        id: 'closedAt',
        filter: 'fuzzyText',
        accessor: (cashRegisterCheck: CashRegisterCheck) => {
          if (cashRegisterCheck.type === CashRegisterType.Open) {
            return '-';
          } else {
            return moment(cashRegisterCheck.closingDate).format('DD/MM/YYYY HH:mm');
          }
        },
        Cell: ({ row }: any) => {
          const cashRegisterCheck: CashRegisterCheck = row.original;
          if (cashRegisterCheck.type === CashRegisterType.Open) {
            return '-';
          } else {
            return moment(cashRegisterCheck.closingDate).format('DD/MM/YYYY HH:mm');
          }
        },
      },
      /* {
        Header: 'Usuario apertura',
        accessor: 'openingUser.name',
        Filter: OpeningUserColumnFilter,
        filter: UserSelectFilter,
        Cell: ({ row }: any) => {
          const user: User = row.original.openingUser;
          return `${user.name} ${user.surnames}`;
        },
      },
      {
        Header: 'Usuario cierre',
        accessor: 'closingUser.name',
        Filter: ClosingUserColumnFilter,
        filter: UserSelectFilter,
        Cell: ({ row }: any) => {
          const user: User = row.original.closingUser;
          return user ? `${user.name} ${user.surnames}` : '';
        },
      }, */
      {
        Header: 'Efectivo día',
        accessor: 'totalDailyCash',
        Cell: ({ value }: any) => {
          if (value == null) {
            return '-';
          } else {
            return <span className={clsx('fw-bold', { 'text-danger': value < 0, 'text-success': value >= 0 })}>{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>;
          }
        },
      },
      {
        Header: 'TPV día',
        accessor: 'totalDailyCards',
        Cell: ({ value }: any) => {
          if (value == null) {
            return '-';
          } else {
            return <span className={clsx('fw-bold', { 'text-danger': value < 0, 'text-success': value >= 0 })}>{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>;
          }
        },
      },
      {
        Header: 'Caja calculada',
        accessor: 'totalRegisterCash',
        Cell: ({ value }: any) => {
          if (value == null) {
            return '-';
          } else {
            return <span className={clsx('fw-bold', { 'text-danger': value < 0, 'text-success': value >= 0 })}>{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>;
          }
        },
      },
      {
        Header: 'Efectivo cierre',
        accessor: 'closingCash',
        Cell: ({ value }: any) => {
          if (value == null) {
            return '-';
          } else {
            return <span className={clsx('fw-bold', { 'text-danger': value < 0, 'text-success': value >= 0 })}>{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>;
          }
        },
      },
      {
        Header: 'Retiradas cierre',
        accessor: 'closingWithdrawals',
        Cell: ({ value }: any) => {
          if (value == null) {
            return '-';
          } else {
            return <span className={clsx('fw-bold', { 'text-danger': value < 0, 'text-success': value >= 0 })}>{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>;
          }
        },
      },
      {
        Header: 'Descuadre apertura',
        accessor: 'openingMismatch',
        Cell: ({ value, row }: any) => {
          // return <span className={clsx('fw-bold', { 'text-danger': value < 0, 'text-success': value >= 0 })}>{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>;
          const cashRegisterCheck = row.original as CashRegisterCheck;
          return (
            <span
              className={clsx('fw-bold', {
                'text-danger': cashRegisterCheck.openingStatus === CashRegisterStatus.Incidence,
                'text-success': cashRegisterCheck.openingStatus === CashRegisterStatus.Closed,
                'text-warning': cashRegisterCheck.openingStatus === CashRegisterStatus.Resolved,
              })}
            >{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>
          );
        },
      },
      {
        Header: 'Descuadre cierre',
        accessor: 'closingMismatch',
        Cell: ({ value, row }: any) => {
          if (value == null) {
            return '-';
          } else {
            const cashRegisterCheck = row.original as CashRegisterCheck;
            return (
              <span
                className={clsx('fw-bold', {
                  'text-danger': cashRegisterCheck.closingStatus === CashRegisterStatus.Incidence,
                  'text-success': cashRegisterCheck.closingStatus === CashRegisterStatus.Closed,
                  'text-warning': cashRegisterCheck.closingStatus === CashRegisterStatus.Resolved,
                })}
              >{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>
            );
          }
        },
      },
      /* {
        Header: 'Saldo final caja',
        accessor: 'endBalance',
        Cell: ({ value }: any) => {
          if (value == null) {
            return '-';
          } else {
            return <span className={clsx('fw-bold', { 'text-danger': value < 0, 'text-success': value >= 0 })}>{`${value > 0 ? '+' : ''}${formatNumber(value)} €`}</span>;
          }
        },
      }, */
      {
        Header: 'Apertura',
        accessor: 'openingStatus',
        Filter: CashRegisterStatusColumnFilter,
        Cell: ({ value }: any) => {
          let className = '';
          let textOption = '';
          switch (value) {
            case CashRegisterStatus.Closed:
              textOption = '';
              className = 'cash-register-closed status-mark';
              break;
            case CashRegisterStatus.Resolved:
              textOption = '';
              className = 'cash-register-resolved status-mark';
              break;
            case CashRegisterStatus.Incidence:
              textOption = '';
              className = 'cash-register-incidence status-mark';
              break;
          }
          return <span className={`badge ${className}`}>{textOption}</span>;
        },
      },
      {
        Header: 'Cierre',
        accessor: 'closingStatus',
        Filter: CashRegisterStatusColumnFilter,
        Cell: ({ value }: any) => {
          let className = '';
          let textOption = '';
          switch (value) {
            case CashRegisterStatus.Closed:
              textOption = '';
              className = 'cash-register-closed status-mark';
              break;
            case CashRegisterStatus.Resolved:
              textOption = '';
              className = 'cash-register-resolved status-mark';
              break;
            case CashRegisterStatus.Incidence:
              textOption = '';
              className = 'cash-register-incidence status-mark';
              break;
          }
          return <span className={`badge ${className}`}>{textOption}</span>;
        },
      },
    ];
    if (user.role === Role.SuperAdmin) {
      columnsData.push({
        Header: 'Acciones',
        Cell: ({ row }: any) => (
          <Trash2
            type="button"
            className="mx-2"
            onClick={() => {
              setSelectedCashRegister(row.original);
              setShowConfirmModal(true);
            }}
            size={14}
          />
        ),
      });
    }
    return columnsData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStoreId, cashRegisters]);

  const deleteCashRegister = async (c: CashRegisterCheck) => {
    try {
      await api.deleteCashRegister(c.id);
      const crs: CashRegisterCheck[] = [...cashRegisters];
      const index: number = crs.findIndex((cr: CashRegisterCheck) => cr.id === c.id);
      crs.splice(index, 1);
      setCashRegisters(crs);
      myToastr.success('Arqueo eliminado correctamente');
    } catch (e: any) {
      myToastr.error(e.response.data.message);
    }
  };

  const generateCsv = () => {
    const data: any[] = [];
    filteredRows.forEach((fr: any) => {
      const cr: CashRegisterCheck = fr.original;
      let openingUser: string = `${cr.openingUser.name}`;
      if (cr.openingUser?.surnames) {
        openingUser += ` ${cr.openingUser.surnames}`;
      }
      let closingUser: string = '';
      if (cr.type === CashRegisterType.Close) {
        closingUser = `${cr.closingUser.name}`;
        if (cr.closingUser?.surnames) {
          closingUser += ` ${cr.closingUser.surnames}`;
        }
      }
      let openingStatus = '';
      switch (cr.openingStatus) {
        case CashRegisterStatus.Closed:
          openingStatus = 'Cerrado';
          break;
        case CashRegisterStatus.Resolved:
          openingStatus = 'Resuelto';
          break;
        case CashRegisterStatus.Incidence:
          openingStatus = 'Incidencia';
          break;
      }
      let closingStatus = '';
      switch (cr.closingStatus) {
        case CashRegisterStatus.Closed:
          closingStatus = 'Cerrado';
          break;
        case CashRegisterStatus.Resolved:
          closingStatus = 'Resuelto';
          break;
        case CashRegisterStatus.Incidence:
          closingStatus = 'Incidencia';
          break;
      }
      data.push({
        Número: cr.internalId,
        Tienda: cr.store.name,
        'Fecha apertura': moment(cr.createdAt).format('DD/MM/YYYY HH:mm'),
        'Fecha cierre': cr.type === CashRegisterType.Close ? moment(cr.closingDate).format('DD/MM/YYYY HH:mm') : '-',
        'Usuario apertura': openingUser,
        'Usuario cierre': closingUser,
        'Efectivo día': cr.totalDailyCash != null ? `${formatNumber(cr.totalDailyCash)}€` : '',
        'TPV día': cr.totalDailyCards != null ? `${formatNumber(cr.totalDailyCards)}€` : '',
        'Caja calculada': cr.totalRegisterCash != null ? `${formatNumber(cr.totalRegisterCash)}€` : '',
        'Efectivo cierre': cr.closingCash != null ? `${formatNumber(cr.closingCash)}€` : '',
        'Retiradas cierre': cr.closingWithdrawals != null ? `${formatNumber(cr.closingWithdrawals)}€` : '',
        'Descuadre apertura': `${formatNumber(cr.openingMismatch)}€`,
        'Descuadre cierre': `${formatNumber(cr.closingMismatch)}€`,
        'Saldo final caja': cr.endBalance != null ? `${formatNumber(cr.endBalance)}€` : '',
        'Estado apertura': openingStatus,
        'Estado cierre': closingStatus,
      });
    });
    setCsvData(data);
  };

  if (stores.length === 0) {
    return <NoStoresAssigned />;
  }

  if (requesting) {
    return (
      <div className="loader">
        <Loader type="TailSpin" color="#252E3C" height={75} width={75} />
      </div>
    );
  }

  return (
    <div className="cash-register report p-4">
      <div className="d-flex flex-row align-items-center mb-2">
        <div className="d-flex align-items-center flex-grow-1">
          <h1 className="me-4">Arqueos de caja</h1>
          <div className="check-switch">
            <input type="checkbox" checked={showTotals} onChange={(e: any) => setShowTotals(e.target.checked)} />
            <label className="check-totals">Totales</label>
          </div>
        </div>
        {filteredRows.length > 0 && (
          <CSVLink
            filename={store ? `arqueos_${store.name}.csv` : 'arqueos.csv'}
            className="d-flex align-items-center export-csv me-3"
            data={csvData}
            onClick={generateCsv}
            title="Exportar Arqueos de caja"
          >
            <Download className="me-1" size={14} /> Exportar
          </CSVLink>
        )}
        <div className="col-md-2">
          <DateRangeSelector
            showTimePicker={false}
            dateRangeData={dateRangeData}
            availableDateRanges={[DateRange.Today, DateRange.Yesterday, DateRange.ThisWeek, DateRange.ThisMonth, DateRange.ThisYear, DateRange.LastYear]}
            onChange={(drd: DateRangeData) => setDateRangeData(drd)}
          />
        </div>
        <button
          className="ms-2 clear-filters"
          disabled={requesting}
          onClick={() => {
            tableRef.current?.clearFilters();
            setDateRangeData({
              dateRange,
              dates: getDatesGivenDateRange(dateRange),
            });
          }}
        >
          Limpiar filtros
        </button>
      </div>
      {showTotals && (
        <div className="totals-wrapper d-flex justify-content-around mb-4">
          <span className="totals-info">Efectivo día: {formatNumber(cashRegistersTotals.totalDailyCash)}€</span>
          <span className="totals-info">TPV día: {formatNumber(cashRegistersTotals.totalDailyCards)}€</span>
          <span className="totals-info">Caja calculada: {formatNumber(cashRegistersTotals.totalRegisterCash)}€</span>
          <span className="totals-info">Retiradas cierre: {formatNumber(cashRegistersTotals.closingWithdrawals)}€</span>
        </div>
      )}
      <Table
        ref={tableRef}
        data={cashRegisters}
        columns={columns}
        noDataMessage="No hay arqueos de caja"
        onFilteredRowsChanged={(filteredRows: any) => setFilteredRows(filteredRows)}
        initialState={{
          pageSize: Constants.LIMIT_RESULTS,
        }}
      />
      {selectedCashRegister && (
        <ConfirmModal
          acceptButtonClass="accept-button"
          show={showConfirmModal}
          title="Eliminar Arqueo"
          content={`¿Estás seguro que quieres eliminar el arqueo ${selectedCashRegister.id}?`}
          closeModal={(result: boolean) => {
            setShowConfirmModal(false);
            if (result) {
              deleteCashRegister(selectedCashRegister);
            }
            setSelectedCashRegister(null);
          }}
        ></ConfirmModal>
      )}
    </div>
  );
};

export default CashRegistersView;
